import OSS from "ali-oss";
export function client() {
  var client = new OSS({
    accessKeyId: "LTAI5t6ddCtPkWmEvxLSLVpA",
    accessKeySecret: "gMGvJGbLv8EjXtgwiUFJG2RjlIlzT3",
    bucket: "sisheng-teacher",
    region: "oss-cn-shenzhen",
  }); //后端提供数据
  return client;
}
const headers = {
  // 指定该Object被下载时的网页缓存行为。
  // "Cache-Control": "no-cache",
  // 指定该Object被下载时的名称。
  // "Content-Disposition": "example.txt",
  // 指定该Object被下载时的内容编码格式。
  // "Content-Encoding": "utf-8",
  // 指定过期时间，单位为毫秒。
  // Expires: "1000",
  // 指定Object的存储类型。
  // "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  "x-oss-object-acl": "public-read",
  // 指定Object标签，可同时设置多个标签。
  // "x-oss-tagging": "Tag1=1&Tag2=2",
  // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  // "x-oss-forbid-overwrite": "true",
};
/**
 * 生成随机uuid
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${+new Date()}_${rx()}${rx()}`;
};
export { headers };
